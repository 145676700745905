<template>
    <div class="view pa24">
        <div class="mb20">
            <el-tabs v-model="webType">
                <el-tab-pane label="住户管理" name="householdTable"></el-tab-pane>
                <el-tab-pane label="游客管理" name="touristTable"></el-tab-pane>
                <el-tab-pane label="用户管理" name="userTable"></el-tab-pane>
            </el-tabs>
        </div>
        <div v-if="webType === 'householdTable'">
            <household-table />
        </div>
        <div v-if="webType === 'touristTable'">
            <tourist-table />
        </div>
        <div v-if="webType === 'userTable'">
            <user-table />
        </div>
    </div>
</template>

<script>
import householdTable from "./pages/householdTable";
import touristTable from "./pages/touristTable";
import userTable from "./pages/userTable";
export default {
    name: "house",
    components: {
        householdTable,
        touristTable,
        userTable
    },
    data() {
        return {
            webType: "householdTable",
        };
    },
    filters: {

    },
    created() {

    },
    methods: {

    },
};
</script>

<style lang="scss" scoped>
.switchInput {
    display: flex;

    .selectType {
        width: 150px;
    }
}

.operation-left {
    margin-bottom: 20px;

    .el-button {
        width: 95px;
        height: 32px;
        background: #51cbcd;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;

        &:active {
            border-color: #51cbcd;
        }
    }
}
</style>